:root {
    --primary: #182033;
    --secondary: #293549;
    --tertiary: #0b1323;
    --doris-primary: #004165;

    --light: #f2f2f2;

    --green: #43cb30;
    --dark-green: #2c8e1f;

    --status-green: var(--green);
    --status-yellow: #fac02b;
    --status-red: #e53a16;

    --font-color: var(--light);
}

html {
    font-size: 16px;
}

* {
    box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--tertiary);
  color: var(--font-color);
  height: 100vh;
}

#root {
    height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
    width: 100%;
}

thead tr {
    background-color: var(--tertiary);
    color: var(--font-color);
}

tr td,
tr th {
    padding: 16px;
}

tbody tr:nth-of-type(odd) {
    background-color: #fff;
}

tr:hover {
    font-weight: 600;
}

.body-scroll-lock {
    overflow-y: hidden;
    padding-right: 15px;
}
